<template>
  <v-card flat class="rounded-t-0">
    <template v-for="(item, index) in filteredItems">
      <v-system-bar :key="'bar' + index">
        <DateValue :value="item.date" long full />
      </v-system-bar>

      <v-list :key="'list' + index" subheader>
        <v-list-item dense>
          <ListIcon
            ><v-icon :color="item.color">{{
              item.icon ? item.icon : "mdi-alert"
            }}</v-icon></ListIcon
          >
          <v-list-item-content>
            <v-list-item-subtitle class="text-wrap">
              {{ item.title }} {{ item.teachersOnly ? "(nur LK)" : "" }}
            </v-list-item-subtitle>
            <v-list-item-title class="text-wrap">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <v-divider />
    <v-card-actions>
      <v-btn text small block @click="save()" :loading="saving" color="primary"
        >ich hab's gelesen</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import items from "@/views/ReleaseNotes/releaseNotes";

export default defineComponent({
  name: "ReleaseNotes",
  components: { DateValue },
  data() {
    return {
      lastDate: "",
      saving: false,
    };
  },
  computed: {
    lastItem() {
      return items[0];
    },
    visbileItems() {
      if (this.$_hasRole("student")) {
        return items.filter((el) => !el.teachersOnly);
      }

      return items;
    },
    filteredItems() {
      if (!this.lastDate) {
        return this.visbileItems.slice(0, 3);
      }
      return this.visbileItems.filter((el) => el.date > this.lastDate);
    },
  },
  methods: {
    async fetchData() {
      this.lastDate = (
        await this.apiList({
          resource: "app/settings",
          query: "key=ReleaseNote",
        })
      ).value;
      if (this.lastDate != this.lastItem.date) {
        this.$emit("loaded");
      }
    },
    async save() {
      this.saving = true;
      await this.apiPost({
        resource: "app/settings",
        data: { key: "ReleaseNote", value: this.lastItem.date },
      });
      this.saving = false;
      this.$emit("close");
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
